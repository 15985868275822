import './App.css';
import Page from 'components/Page';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'Routes';

function App() {

  // useEffect(() => {
  //   console.log('initialize');
  //   google.accounts.id.initialize({
  //     client_id: '345348076432-pgh3rtbev3slj6hrhheeimp5e3lo4rl3.apps.googleusercontent.com',
  //     callback: handleCredentialResponse
  //   });
  //   google.accounts.id.prompt();
  // }, []);

  return (
    <Page>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Page>
  );
}

export default App;
