import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';
import WebBasic from 'views/WebBasic';
import ContactUs from 'views/ContactUs';

const routes = [
  {
    path: '/',
    renderer: (params = {}): JSX.Element => <WebBasic {...params} />,
  },
  {
    path: '/contact-us',
    renderer: (params = {}): JSX.Element => <ContactUs {...params} />,
  }
];

const Routes = (): JSX.Element => {
  return (
    <ReactRoutes>
      {routes.map((item, i) => (
        <Route key={i} path={item.path} element={item.renderer()} />
      ))}
      <Route path="*" element={<Navigate replace to="/" />} />
    </ReactRoutes>
  );
};

export default Routes;
