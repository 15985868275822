import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { EmailForm, useContactUsMutation, useHelloQuery } from 'app/services/uzeable';
import { useCallback } from 'react';
import { LoadingButton } from '@mui/lab';
import { Alert, Snackbar } from '@mui/material';
// import MenuItem from '@mui/material/MenuItem';

const validationSchema = yup.object({
  first_name: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your first name'),
  last_name: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your last name'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  phone: yup
    .string()
    .trim()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)$/,
      'Please enter a valid phone number.',
    ),
  message: yup
    .string()
    .trim()
    .max(500, 'The message cannot contain more than 500 characters'),
});

const Form = (): JSX.Element => {
  const initialValues: EmailForm = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    message: '',
  };

  const [contactUs, { isLoading: isSubmitBusy }] = useContactUsMutation();
  const helloQuery = useHelloQuery();
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [failedOpen, setFailedOpen] = React.useState(false);

  const handleSuccessClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessOpen(false);
  };

  const handleFailedClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setFailedOpen(false);
  };

  const onSubmit = useCallback(async (values) => {
    try {
      await contactUs(values).unwrap();
      setSuccessOpen(true);
    } catch (err) {
      console.log('Submission Error', err);
      setFailedOpen(true);
    }
    return values;
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  // Render API goes to sleep. Turn it on so that the User gets a fast response after submitting
  // This assumes that the user takes more than 30 seconds to fill out the form.
  useEffect(() => {
    const initApi = async () => {
      try {
        console.log(await helloQuery.refetch().unwrap());
      } catch (err) {
        console.log('Hello', err);
      }
    };

    initApi();
  }, []);

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          component={Grid}
          marginBottom={{ xs: 10, sm: 0 }}
          container
          spacing={4}
        >
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Please tell us your name *
            </Typography>
            <TextField
              label="First name"
              variant="outlined"
              name={'first_name'}
              fullWidth
              value={formik.values.first_name}
              onChange={formik.handleChange}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              // @ts-ignore
              helperText={formik.touched.first_name && formik.errors.first_name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Please tell us your name *
            </Typography>
            <TextField
              label="Last name"
              variant="outlined"
              name={'last_name'}
              fullWidth
              value={formik.values.last_name}
              onChange={formik.handleChange}
              error={formik.touched.last_name && Boolean(formik.errors.last_name)}
              // @ts-ignore
              helperText={formik.touched.last_name && formik.errors.last_name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Please enter your email address *
            </Typography>
            <TextField
              label="Email"
              variant="outlined"
              name={'email'}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              // @ts-ignore
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Please enter your phone number (optional)
            </Typography>
            <TextField
              label="Phone number"
              variant="outlined"
              name={'phone'}
              fullWidth
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              // @ts-ignore
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Please tell us the budget
            </Typography>
            <TextField
              select
              label="Project budget"
              variant="outlined"
              name={'budget'}
              fullWidth
              value={formik.values.budget}
              onChange={formik.handleChange}
              error={formik.touched.budget && Boolean(formik.errors.budget)}
              // @ts-ignore
              helperText={formik.touched.budget && formik.errors.budget}
            >
              {[
                '< $20.000',
                '$20.000 - $50.000',
                '$50.000 - $100.000',
                '> $100.000',
              ].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid> */}
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Please tell us about your project (optional)
            </Typography>
            <TextField
              label="Message"
              variant="outlined"
              name={'message'}
              fullWidth
              multiline
              rows={4}
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              // @ts-ignore
              helperText={formik.touched.message && formik.errors.message}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <LoadingButton
              size={'large'}
              variant={'contained'}
              type={'submit'}
              loading={isSubmitBusy}
            >
              Send request
            </LoadingButton>
            <Typography
              variant={'subtitle2'}
              color={'textSecondary'}
              sx={{ marginTop: 2 }}
              align={'center'}
            >
              We'll get back to you in 1-2 business days.
            </Typography>
          </Grid>
        </Box>
        <Snackbar open={successOpen} autoHideDuration={4000} onClose={handleSuccessClose} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
          <Alert onClose={handleSuccessClose} severity="success" sx={{ width: '100%' }}>
            Message sent!
          </Alert>
        </Snackbar>
        <Snackbar open={failedOpen} autoHideDuration={4000} onClose={handleFailedClose} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
          <Alert onClose={handleFailedClose} severity="error" sx={{ width: '100%' }}>
            Error sending message
          </Alert>
        </Snackbar>
      </form>
    </Box>
  );
};

export default Form;
